<template>
  <div
    v-if="isOpen"
    class="fixed right-0 bottom-0 z-top w-full bg-blue-500 p-4 text-white sm:w-[30rem] sm:rounded-tl-xl sm:px-8 sm:pt-8 md:w-[47.25rem] md:px-14 md:pb-8 md:pt-14"
  >
    <p class="text-xs !leading-[1.425] md:text-base">
      Wir verwenden Cookies, um Ihr Nutzererlebnis zu verbessern und den
      Webverkehr zu analysieren. Weitere Informationen finden Sie in unserer
      <NuxtLink
        class="hover-underline-animation-prose text-white hover:text-white"
        to="/datenschutz"
        >Datenschutzerklärung</NuxtLink
      >.
    </p>

    <button
      aria-label="Cookie Banner schliessen"
      class="mt-4 rounded-full border-2 border-white py-1.5 px-4 text-xs transition duration-200 hover:bg-white hover:text-blue-500 md:mt-12 md:py-3 md:px-5"
      @click="closeCookieBanner"
    >
      Schliessen
    </button>
  </div>
</template>

<script lang="ts" setup>
const cookieBannerClosed = localStorage.getItem('cookieBannerClosed')
const isOpen = ref(!cookieBannerClosed)

const closeCookieBanner = () => {
  isOpen.value = false
  localStorage.setItem('cookieBannerClosed', 'true')
}
</script>
